<template>
  <div class="banner-ad" @click="handleClickBanner" v-if="adData.img">
    <img :src="adData.img" alt="互动广告" />
  </div>
</template>

<script>
  import API from '@/api';

  export default {
    name: 'Banner',
    components: {},
    props: {},
    data() {
      return {
        adData: {
          img: '',
        },
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.getBanner();
      console.log(`开始请求广告接口`);
    },
    methods: {
      async getBanner() {
        try {
          this.adData = await this.$http.TaskPost(API.INTERACTIVE_AD_API, { ad_interactive_group_id: 5 });
          console.log(`广告数据`, this.adData);
          this.$track({ page_title: '内容分享页', element_name: '广告曝光' });
        } catch (e) {
          console.log(e);
        }
      },

      handleClickBanner() {
        const { url } = this.adData;

        if (url) window.location.href = url;
        this.$track({ page_title: '内容分享页', element_name: '点击广告' });
      },
    },
  };
</script>

<style lang="less" scoped>
  .banner-ad {
    margin-top: 0.5rem;
    img {
      border-radius: 0.12rem;
      width: 7rem;
    }
  }
</style>
