<template>
  <div class="welcome-modal" @touchmove.prevent>
    <div class="welcome-modal-wrap">
      <div class="welcome-modal-wrap-content">
        <img class="welcome-modal-wrap-content-img__bg" src="@/assets/images/share-content/model-bg.png" alt="快音" />
        <div class="welcome-modal-wrap-content-info">
          <div style="margin-top: 0.25rem">歌曲信息加载完成!</div>
          <div class="welcome-modal-wrap-content-info__like">打开快音立即体验!</div>
          <div
            :data-clipboard-text="inviteCode"
            @click.prevent="openApp('选择弹窗曝光', '#modal-open-app-v2', '去快音听')"
            ref="ModalOpenApp"
            id="modal-open-app-v2"
            class="button-open-app"
          >
            去快音免费听更多歌曲
          </div>
        </div>
        <img
          id="modal-img-close"
          @click.prevent.self="handleModelV2(false)"
          class="welcome-modal-wrap-content-img__close"
          src="@/assets/images/share-content/close.png"
          alt="快音"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      inviteCode: {
        type: String,
        default: '',
      },
      openApp: {
        type: Function,
        default: () => {},
      },
      handleModelV2: {
        type: Function,
        default: () => {},
      },
    },
  };
</script>

<style lang="less" scoped>
  .welcome-modal-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .welcome-modal-wrap-content {
      width: 7rem;
      height: 5.9rem;
      position: relative;
      .welcome-modal-wrap-content-info {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 99;
        font-size: 0.32rem;

        font-weight: 600;
        color: #ffffff;
        .welcome-modal-wrap-content-info__like {
          margin: 0.4rem 0;
        }
        .button-open-app {
          width: 4.74rem;
          height: 0.82rem;
          background: linear-gradient(180deg, #ffe5b6 0%, #ffc17d 100%);
          color: #d63016;
          border-radius: 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .welcome-modal-wrap-content-img__bg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .welcome-modal-wrap-content-img__close {
        width: 0.8rem;
        height: 0.8rem;
        position: absolute;
        bottom: -0.8rem;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
  .welcome-modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.75);
  }
</style>
