<template>
  <div class="wonderful-video-container">
    <div class="title-warp margin-bottom" @click.self="playMusicOrVideo">
      <div class="title-warp-info">精彩视频</div>
      <div
        :data-clipboard-text="inviteCode"
        @click.prevent="openApp('更多', '#video-more-content')"
        id="video-more-content"
        class="title-warp-more"
      >
        <span>更多</span>
        <img class="title-warp-more-right" src="@/assets/images/share-content/right.png" alt="快音" />
      </div>
    </div>
    <div class="hot-video-list-warp">
      <div
        @click="playRecommendVideo(item.video_id, uid, `#hot-video-btn${k}`, item.share_info)"
        v-for="(item, k) in hotVideoList"
        :key="item.video_id"
        :data-clipboard-text="inviteCode"
        class="hot-video-list-warp-item"
        :id="`hot-video-btn${k}`"
      >
        <img
          class="video-list-item-bg"
          :src="item.cover || require('../../../../../assets/images/share-content/logo.png')"
          alt="快音"
        />
        <div class="video-list-item-content">
          <div class="video-list-item-content-top">
            <div class="video-list-item-content-toast">
              <img
                :data-video="item.video_id"
                :data-sharefrom="uid"
                class="video-list-item-img__fire"
                src="@/assets/images/share-content/fire.png"
                alt="快音"
              />
              <div class="video-list-item-fire-num">
                {{ item.like_count || item.like_count }}
              </div>
            </div>
          </div>
          <div class="video-list-item-bot">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      inviteCode: {
        type: String,
        default: '',
      },
      hotVideoList: {
        type: Array,
        default: () => [],
      },
      uid: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('share/contentLanding', ['mediaInfo']),
    },
    methods: {
      /**
       * @description 下载app
       */
      openApp(element_name, element_id) {
        this.$emit('openApp', element_name, element_id);
      },
      /**
       * @description 推荐视频
       * @param {string} video_id 视频id
       * @param {string} share_from uid
       * @param {String} element_id
       * @param {Object} share_info
       */
      playRecommendVideo(video_id, share_from, element_id, share_info) {
        if (this.mediaInfo.is_download === 'a') {
          this.openApp('看视频下载', element_id);
        } else {
          this.$emit('playRecommendVideo', video_id, share_from, share_info);
        }
      },
      /**
       * @description 播放音乐、视频
       */
      playMusicOrVideo() {
        this.$emit('playMusicOrVideo');
      },
    },
  };
</script>

<style lang="less" scoped>
  .wonderful-video-container {
    .title-warp {
      padding: 0 0.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      .title-warp-info {
        font-size: 0.32rem;
        font-weight: 600;
      }
      .title-warp-more {
        display: flex;
        align-items: center;
        font-size: 0.32rem;
        .title-warp-more-right {
          width: 0.28rem;
          height: 0.28rem;
        }
      }
    }
    width: 100%;
    margin-top: 0.6rem;
    .margin-bottom {
      margin-bottom: 0.1rem;
    }
    .hot-video-list-warp {
      padding: 0 0.4rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .hot-video-list-warp-item {
        margin-top: 0.2rem;
        width: 2.14rem;
        height: 2.14rem;

        position: relative;
        .video-list-item-bg {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 0.2rem;
          object-fit: cover;
        }
        .video-list-item-content {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 9;
          display: flex;
          flex-direction: column;
          .video-list-item-content-top {
            flex: 1;
            .video-list-item-content-toast {
              position: absolute;
              top: 0;
              right: 0;
              background: #ff2b3d;
              color: #fff;
              display: flex;
              align-items: center;
              font-size: 0.2rem;
              border-radius: 0 0.2rem 0 0.2rem;
              padding: 0 0.1rem;
              .video-list-item-img__fire {
                width: 0.24rem;
                height: 0.24rem;
              }
            }
          }
          .video-list-item-bot {
            width: 1.8rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 0.2rem;
            padding-left: 0.2rem;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.6rem;
          }
        }
      }
    }
  }
</style>
