<template>
  <div @click.self="playMusicOrVideo" class="common_container">
    <div class="button-open-app">
      <img class="button-open-app-beam" src="@/assets/images/share-content/beam.png" alt="快音" />
      <div
        :data-clipboard-text="mediaInfo.invite_code"
        class="button-open-app—info btn_content"
        @click="openApp(topBtnInfo, '#button-top-open-app')"
        id="button-top-open-app"
      >
        {{ MusicContentAb === 'a' ? topBtnInfo : '快打开快音,海量热歌免费听' }}
      </div>
    </div>
    <ADBanner v-if="musicOrVideoShow && mediaInfo.has_ad" />
    <!-- 视频页和音乐页显示顺序不一致 -->
    <WonderfulVideo
      :uid="mediaInfo.uid"
      :hot-video-list="hotVideoList"
      v-if="!musicOrVideoShow && hotVideoList.length > 0"
      :inviteCode="mediaInfo.invite_code"
      @openApp="openApp"
      @playRecommendVideo="playRecommendVideo"
      @playMusicOrVideo="playMusicOrVideo"
    ></WonderfulVideo>
    <SameMusic
      v-if="hotMusicList.length > 0"
      :hot-music-list="hotMusicList"
      :uid="mediaInfo.uid"
      @playRecommendMusic="playRecommendMusic"
      @openApp="openApp"
      @playMusciOrVideo="playMusicOrVideo"
      :inviteCode="mediaInfo.invite_code"
    ></SameMusic>
    <WonderfulVideo
      :uid="mediaInfo.uid"
      :hot-video-list="hotVideoList"
      v-if="musicOrVideoShow && hotVideoList.length > 0"
      :inviteCode="mediaInfo.invite_code"
      @openApp="openApp"
      @playRecommendVideo="playRecommendVideo"
      @playMusicOrVideo="playMusicOrVideo"
    ></WonderfulVideo>

    <div class="button-open-app" style="margin: 0.5rem 0">
      <img class="button-open-app-beam delay_animation" src="@/assets/images/share-content/beam.png" alt="快音" />
      <div
        :data-clipboard-text="mediaInfo.invite_code"
        class="button-open-app—info"
        @click="openApp(botBtnInfo, '#button-bot-open-app')"
        id="button-bot-open-app"
      >
        {{ MusicContentAb === 'a' ? botBtnInfo : '快打开快音,海量热歌免费听' }}
      </div>
    </div>
  </div>
</template>

<script>
  import WonderfulVideo from './WonderfulVideo';
  import SameMusic from './SameMusic';
  import ADBanner from './Banner';
  import { mapGetters } from 'vuex';

  export default {
    components: { WonderfulVideo, ADBanner, SameMusic },
    props: {
      mediaInfo: {
        type: Object,
        default: () => {},
      },
      musicOrVideoShow: {
        // true 显示音乐 ; false 显示视频播放
        type: Boolean,
        default: true,
      },
      hotMusicList: {
        type: Array,
        default: () => [],
      },
      hotVideoList: {
        type: Array,
        default: () => [],
      },
      openApp: {
        type: Function,
        default: () => {},
      },
    },
    computed: {
      topBtnInfo() {
        return this.musicOrVideoShow ? '快打开快音,听更多精彩歌曲' : '快打开快音,看更多精彩视频';
      },
      botBtnInfo() {
        return this.musicOrVideoShow ? '快打开快音,看更多精彩视频' : '快打开快音,听更多精彩歌曲';
      },

      ...mapGetters('share/contentLanding', ['MusicContentAb']),
    },
    methods: {
      /**
       * @description 推荐音乐
       */

      playRecommendMusic(music_code, share_from, share_info) {
        this.$emit('playRecommendMusic', music_code, share_from, share_info);
      },
      /**
       * @description 推荐视频
       */

      playRecommendVideo(video_id, share_from, share_info) {
        this.$emit('playRecommendVideo', video_id, share_from, share_info);
      },
      /**
       * @description 播放音乐、视频
       * @return @void
       */
      playMusicOrVideo() {
        this.$emit('playMusicOrVideo');
      },
    },
  };
</script>

<style lang="less" scoped>
  .common_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .button-open-app {
      width: 6.8rem;
      height: 1rem;
      background: linear-gradient(220deg, #ff709b 0%, #8129ff 100%);
      color: #fff;
      border-radius: 0.5rem;
      border: 2px solid #f7d470;
      position: relative;
      margin: 0.5rem 0 0 0;
      display: flex;
      align-items: center;
      overflow: hidden;
      .button-open-app—info {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.32rem;
        font-weight: 600;
      }
      .button-open-app-beam {
        width: 0.92rem;
        height: 1rem;
        margin-left: -0.6rem;
        animation: beamMove 3s linear infinite;
      }
      .delay_animation {
        animation-delay: 2s;
      }
      @keyframes beamMove {
        0% {
          margin-left: -0.6rem;
          display: inline-block;
        }

        100% {
          margin-left: 6.2rem;
          display: none;
        }
      }
    }
  }
</style>
