<template>
  <div class="same-music">
    <div @click.self="playMusicOrVideo" class="same-music-title">
      <div class="same-music-title-info">相似歌曲</div>
      <div
        :data-clipboard-text="inviteCode"
        @click.prevent="openApp('更多', '#more-content')"
        id="more-content"
        class="same-music-title-more"
      >
        <span>更多</span>
        <img class="same-music-title-more-right" src="@/assets/images/share-content/right.png" alt="快音" />
      </div>
    </div>
    <div class="music-list-container">
      <div
        v-for="(item, k) in hotMusicList"
        :key="item.music_code"
        @click.self="playMusicOrVideo"
        class="music-list-item"
      >
        <img
          class="music-list-item-logo"
          :src="item.cover || item.avatar_url"
          alt="快音"
          v-if="item.cover || item.avatar_url"
        />
        <div class="music-list-item-intro">
          <div class="sing_name">{{ item.music_name }}</div>
          <div class="sing_author">{{ item.nickname }}</div>
        </div>
        <div
          :id="`music-play-btn${k}`"
          @click="playRecommendMusic(item.music_code, uid, `#music-play-btn${k}`, item.share_info)"
          class="button-play"
        >
          点击播放
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    components: {},
    props: {
      inviteCode: {
        type: String,
        default: '',
      },
      hotMusicList: {
        type: Array,
        default: () => [],
      },
      uid: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('share/contentLanding', ['mediaInfo']),
    },
    methods: {
      /**
       * @description 下载app
       */

      openApp(element_name, element_id) {
        this.$emit('openApp', element_name, element_id);
      },
      /**
       * @description 推荐音乐
       */
      playRecommendMusic(music_code, share_from, element_id, share_info) {
        if (this.mediaInfo.is_download === 'a') {
          this.openApp('点击播放', element_id);
        } else {
          this.$emit('playRecommendMusic', music_code, share_from, share_info);
        }
      },
      /**
       * @description 播放视频、音乐
       */
      playMusicOrVideo() {
        this.$emit('playMusicOrVideo');
      },
    },
  };
</script>

<style lang="less" scoped>
  .same-music {
    width: 100%;
    margin-top: 0.6rem;
    .music-list-container {
      padding: 0 0.4rem;
      .music-list-item {
        display: flex;
        padding: 0.2rem 0;
        align-items: center;
        .music-list-item-logo {
          width: 1rem;
          height: 1rem;
          border-radius: 0.16rem;
        }
        .music-list-item-intro {
          flex: 1;
          margin-left: 0.3rem;
          .sing_name {
            width: 3.6rem;
            font-size: 0.28rem;
            font-weight: 600;
            color: #fff;
            line-height: 0.4rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .sing_author {
            width: 3.6rem;
            font-size: 0.24rem;
            line-height: 0.4rem;
            font-weight: 300;
            color: #bbbbbb;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .button-play {
          width: 1.84rem;
          height: 0.6rem;
          color: #fff;
          background: #ff2b3d;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.5rem;
          font-size: 0.28rem;
          font-weight: 600;
        }
      }
    }
  }

  .same-music-title {
    padding: 0 0.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    .same-music-title-info {
      font-size: 0.32rem;
      font-weight: 600;
    }
    .same-music-title-more {
      display: flex;
      align-items: center;
      font-size: 0.32rem;
      .same-music-title-more-right {
        width: 0.28rem;
        height: 0.28rem;
      }
    }
  }
</style>
